<template>
  <div class="custom-modal-footer custom-z-index">
    <slot></slot>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CustomModalFooter',
  data() {
    return {
      readingBusyName: 'FOLLOWINGLISTREADING',

      init: false,
    };
  },
  props: {
  },
  components: {
  },
  computed: {

  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.modal-footer {

}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.custom-modal-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $color-white;
  border-radius: 1rem 1rem 0 0;
  padding: 1rem;
  box-shadow: 0px -4px 20px 0px #00000014;
}
</style>
  